// All variables has to be `!default`'ed in order to be able to
// be overrided by add-ons
@use "sass:map";

// // Semantic UI related - Sync'd
// Colors
$text-color: #000 !default;
$font-size: 18px !default;
$line-height: 24px !default;

// Headers font size
$heading1: 60px !default;
$heading2: 30px !default;
$heading3: 24px !default;

$block-title-h2: 42px !default;

$heading-title-top-spacing: 100px !default;
$heading-title-bottom-spacing: 75px !default;
$padding-colored-pills: 20px !default;

$heading-text-top-spacing: 50px !default;
$heading-text-bottom-spacing: 25px !default;

// Fonts
$custom-fontname: 'Inter' !default;
$page-font-template: $custom-fontname, sans-serif !default;
$page-font: var(--custom-main-font, $page-font-template) !default;
$header-font: var(--custom-headers-font, $page-font) !default;

// Breakpoints
// Mobile / Tablet portrait < 769 | Tablet landscape / Small desktop < 941 | Computer desktop < 1440 | Large Monitor > 1440
$largest-mobile-screen: 768px !default; // not finished in `-width` for historical reasons (SemanticUI naming)
$tablet-breakpoint: 769px !default;
$computer-width: 940px !default;
$computer-breakpoint: 941px !default;
$large-monitor-width: 1440px !default;
$large-monitor-breakpoint: 1441px !default;

// Rest of theme variables

:root {
  --text-color: #666;
  --text-size: 18px;
}

// Colors
$white: #fff !default;
$veryLightGrey: #eee !default;
$darkGrey: #555555 !default;
$black: #000 !default;
$grey: #666 !default;
$lightgrey: #ecebeb !default;
$blue-for-grey-contrast: #0070a2 !default;
$brown: #826a6a !default;
$blueArctic: #e2f1fd !default;
$greySnow: #f3f5f7 !default;
$greySmoke: #e4e8ec !default;
$darkBlue: #023d6b !default;
$secondary-grey: #ececec !default;

// Image Aspect Ratio
$aspect-ratio: var(--image-aspect-ratio, 16/9) !default;

// Weights
$thin: 100 !default;
$extra-light: 200 !default;
$light: 300 !default;
$regular: 400 !default;
$medium: 500 !default;
$semi-bold: 600 !default;
$bold: 700 !default;
$extra-bold: 800 !default;
$bolder: 900 !default;

// Vertical Spacing
$block-vertical-space: 25px !default;

@mixin vertical-space-h1() {
  margin-top: 40px;
  margin-bottom: 80px;
}

@mixin vertical-space-h2() {
  margin-top: 80px;
  margin-bottom: 40px;
}

@mixin vertical-space-h3() {
  margin-top: 60px;
  margin-bottom: 20px;
}

@mixin vertical-space-h4() {
  margin-top: 60px;
  margin-bottom: 20px;
}

@mixin vertical-space-heading() {
  margin-top: 80px;
  margin-bottom: 80px;
}

@mixin vertical-space-introduction() {
  margin-top: 80px;
  margin-bottom: 80px;
}

@mixin vertical-space-button() {
  margin-top: 40px;
  margin-bottom: 80px;
}

@mixin vertical-space-teaser() {
  margin-bottom: 40px;
}

// Change of color
$color-block-change-vertical-spacing: 100px !default;

// Grids
$grid-block-vertical-spacing-top: 100px !default;
$grid-block-vertical-spacing-bottom: 100px !default;

// Maps
$font-weights: (
  thin: 100,
  extra-light: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semi-bold: 600,
  bold: 700,
  extra-bold: 800,
  bolder: 900,
);

$font-sizes: (
  xs: 12px,
  s: 14px,
  m: 18px,
  l: 24px,
  xl: 30px,
  2xl: 36px,
  3xl: 48px,
);

$line-heights: (
  xs: 16px,
  s: 18px,
  m: 24px,
  l: 30px,
  xl: 33px,
  2xl: 36px,
  3xl: 48px,
  4xl: 56px,
);

@mixin add($property, $value) {
  @if $property == size {
    #{"font-" + $property}: map.get($font-sizes, $value);
  } @else if $property == height {
    #{"line-" + $property}: map.get($line-heights, $value);
  } @else if $property == weight {
    #{"font-" + $property}: map.get($font-weights, $value);
  }
}

// Patterns
@mixin body-text() {
  @include add(size, m);
  @include add(height, m);
  @include add(weight, light);
}

@mixin body-text-bold() {
  @include add(size, m);
  @include add(height, m);
  @include add(weight, bold);
}

@mixin page-title() {
  @include add(size, 3xl);
  @include add(height, 4xl);
  @include add(weight, bold);
}

@mixin block-title() {
  @include add(size, xl);
  @include add(height, 2xl);
  @include add(weight, light);
}

@mixin text-heading-h2() {
  @include add(size, xl);
  @include add(height, 2xl);
  @include add(weight, bold);
}

@mixin text-heading-h3() {
  @include add(size, l);
  @include add(height, l);
  @include add(weight, bold);
}

@mixin text-heading-h4() {
  @include add(size, m);
  @include add(height, m);
  @include add(weight, bold);
}

@mixin introduction() {
  @include add(size, l);
  @include add(height, xl);
  @include add(weight, light);
}

@mixin headtitle1() {
  @include add(size, s);
  @include add(height, s);
  @include add(weight, bold);
}

@mixin headtitle2() {
  @include add(size, xs);
  @include add(height, xs);
  @include add(weight, bold);
}

@mixin highlight-title() {
  @include add(size, 2xl);
  @include add(height, 3xl);
  @include add(weight, bold);
}

@mixin marginal-title() {
  @include add(size, s);
  @include add(height, s);
  @include add(weight, bold);
}

@mixin marginal-description() {
  @include add(size, s);
  @include add(height, s);
  @include add(weight, light);
}
