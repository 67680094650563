/*
Font families defined by this CSS:

- "Inter"                  static "traditional" fonts for older web browsers
- "Inter var"              single-axis variable fonts for all modern browsers
- "Inter var experimental" multi-axis variable fonts for some modern web browsers

Use like this in your CSS:

:root { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  :root { font-family: 'Inter var', sans-serif; }
}

------------------------- static ------------------------- */
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src: url('fonts/Inter-Thin.woff2?v=3.19') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: italic;
  font-weight: 100;
  src: url('fonts/Inter-ThinItalic.woff2?v=3.19') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: url('fonts/Inter-ExtraLight.woff2?v=3.19') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: italic;
  font-weight: 200;
  src: url('fonts/Inter-ExtraLightItalic.woff2?v=3.19') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/Inter-Light.woff2?v=3.19') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: italic;
  font-weight: 300;
  src: url('fonts/Inter-LightItalic.woff2?v=3.19') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Inter-Regular.woff2?v=3.19') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  src: url('fonts/Inter-Italic.woff2?v=3.19') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/Inter-Medium.woff2?v=3.19') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  src: url('fonts/Inter-MediumItalic.woff2?v=3.19') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('fonts/Inter-SemiBold.woff2?v=3.19') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  src: url('fonts/Inter-SemiBoldItalic.woff2?v=3.19') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/Inter-Bold.woff2?v=3.19') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  src: url('fonts/Inter-BoldItalic.woff2?v=3.19') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url('fonts/Inter-ExtraBold.woff2?v=3.19') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: italic;
  font-weight: 800;
  src: url('fonts/Inter-ExtraBoldItalic.woff2?v=3.19') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url('fonts/Inter-Black.woff2?v=3.19') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: italic;
  font-weight: 900;
  src: url('fonts/Inter-BlackItalic.woff2?v=3.19') format('woff2');
}

/* ----------------------- variable ----------------------- */

@font-face {
  font-display: swap;
  font-family: 'Inter var';
  font-named-instance: 'Regular';
  font-style: normal;
  font-weight: 100 900;
  src: url('fonts/Inter-roman.var.woff2?v=3.19') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Inter var';
  font-named-instance: 'Italic';
  font-style: italic;
  font-weight: 100 900;
  src: url('fonts/Inter-italic.var.woff2?v=3.19') format('woff2');
}

/* ----------- experimental multi-axis variable -----------

Slant axis is not yet widely supported (as of February 2019) and thus this
multi-axis single-file variable font is opt-in rather than the default.
When using this, you will likely need to set font-variation-settings explicitly, e.g:
* { font-variation-settings: "slnt" 0deg }
.italic { font-variation-settings: "slnt" 10deg }
*/

@font-face {
  font-display: swap;
  font-family: 'Inter var experimental';
  font-style: oblique 0deg 10deg;
  font-weight: 100 900;
  src: url('fonts/Inter.var.woff2?v=3.19') format('woff2');
}

/* Legacy name (became legacy on Feb 2, 2019) */
@font-face {
  font-display: swap;
  font-family: 'Inter var alt';
  font-named-instance: 'Regular';
  font-style: normal;
  font-weight: 100 900;
  src: url('fonts/Inter-roman.var.woff2?v=3.19') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Inter var alt';
  font-named-instance: 'Italic';
  font-style: italic;
  font-weight: 100 900;
  src: url('fonts/Inter-italic.var.woff2?v=3.19') format('woff2');
}

@font-face {
  font-family: 'Caviar-Dreams';
  font-style: normal;
  font-weight: normal;
  src: url('fonts/Caviar-Dreams.ttf.woff') format('woff'),
    url('fonts/Caviar-Dreams.ttf.svg#Caviar-Dreams') format('svg'),
    url('fonts/Caviar-Dreams.ttf.eot'),
    url('fonts/Caviar-Dreams.ttf.eot?#iefix') format('embedded-opentype');
}

// Headers
h1.documentFirstHeading {
  border: none;
  margin-bottom: 1.5rem;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  @media only screen and (max-width: $largest-mobile-screen) {
    margin-bottom: 1rem;
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
  }
}

@mixin h2_headline() {
  h2.headline {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 30px !important;
    font-weight: 700 !important;
    line-height: 36px !important;
    @media only screen and (max-width: $largest-mobile-screen) {
      margin-right: 2rem !important;
      margin-left: 2rem !important;
      text-align: center !important;
    }
  }
}
