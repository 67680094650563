@use 'sass:math';

// Variables file
$white: #fff !default;
$veryLightGrey: #eee !default;
$darkGrey: #555555 !default;
$black: #000 !default;
$grey: #666 !default;
$lightgrey: #ecebeb !default;
$blue-for-grey-contrast: #0070a2 !default;
$brown: #826a6a !default;
$blueArctic: #e2f1fd !default;
$greySnow: #f3f5f7 !default;
$greySmoke: #e4e8ec !default;
$darkBlue: #023d6b !default;
$secondary-grey: #ececec !default;

$brand: #ee6d1c;
$brandlight: #f3965c;
$brandalternate: $black;
$brandconstrast: $white;

$page-background: $white;

$_narrow-container-width: 1100px !default;
$_default-container-width: 1100px !default;

$link-color: $darkBlue;
$link-color-hover: $brand;

$background-containers: $brand;

$custom-fontname: 'Inter' !default;
$page-font-template: $custom-fontname, sans-serif !default;

$alternate-fontname: 'Caviar-Dreams', sans-serif !default;

// Images
// Image Aspect Ratio
$aspect-ratio: math.div(16, 9) !default;
$teaser-images-aspect-ratio: math.div(16, 9) !default;

// Animation
@mixin animate-hover() {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-4px);
  -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@mixin theme-gradient() {
  background: linear-gradient(180deg, $brand 0, $brandlight 100%);
}

// Button
@mixin rounded_button() {
  button.ui.button {
    padding: 10px 24px;
    border: 1px solid $brand;
    background: $brand;
    border-radius: 41px;
    color: $white;
    font-size: 16px;
    font-weight: 600;

    &:hover {
      @include animate-hover();
      @include theme-gradient();
      border: 1px solid $brand;
      background: $brand;
    }

    &.primary {
      padding: 10px 24px;
      border: 1px solid $brand;
      background: $brand;
      border-radius: 41px;
      color: $white;
      font-size: 16px;
      font-weight: 600;

      &:hover {
        @include animate-hover();
        @include theme-gradient();
        border: 1px solid $brand;
        background: $brand;
      }
    }
  }
}
