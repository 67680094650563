// Links
a {
  color: $link-color;
  text-decoration: none;

  &:hover {
    color: $link-color-hover;
    text-decoration: none;
  }
}
