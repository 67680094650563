:root {
  --layout-container-width: 1440px;
  --default-container-width: 940px;
  --narrow-container-width: 620px;
}

// Container queries still do not work with CSS properties
// They should be exact numbers
// For now, maintain in sync with the above
$layout-container-width: 1440px;
$default-container-width: 940px;
$narrow-container-width: 620px;

@mixin narrow-container-width() {
  max-width: var(--narrow-container-width);
  margin-right: auto;
  margin-left: auto;
}

@mixin default-container-width() {
  max-width: var(--default-container-width);
  margin-right: auto;
  margin-left: auto;
}

@mixin layout-container-width() {
  max-width: var(--layout-container-width);
  margin-right: auto;
  margin-left: auto;
}

// One still cannot use variables or CSS properties directly on @container queries
@mixin adjustMarginsToContainer($width) {
  @container (max-width: #{$width - 1}) {
    margin-right: 2rem;
    margin-left: 2rem;
  }
}

// Container adjustments for dealing correctly with absoluted elements
@mixin container-preference-order($zindex) {
  position: relative;
  z-index: $zindex;
}

// We expect initially three main containers
.header-wrapper {
  .a.container {
    @include container-preference-order(12);
  }
}

.content-area {
  .a.container {
    @include container-preference-order(10);
  }
}

footer {
  .a.container {
    @include container-preference-order(8);
  }
}

// If using popperjs, we need also to set preference too to the
// popperjs container.
[data-popper-placement] {
  @include container-preference-order(100);
}

// Container widths for configurable via block styling wrapper
// TODO: consider renaming "align" property to something more meaningful
.has--align--center {
  @include narrow-container-width();
}

.has--align--wide {
  @include default-container-width();
}

.has--align--full {
  @include layout-container-width();
}

// Container definitions for elements
#page-document > * {
  @include layout-container-width();
}

#page-history > * {
  @include layout-container-width();
}

#page-contents > * {
  @include layout-container-width();
}

.header-wrapper .header,
.breadcrumbs .breadcrumb {
  @include layout-container-width();
  @include adjustMarginsToContainer($layout-container-width);
}

// Content Layout Styling
#page-document .blocks-group-wrapper {
  & > h2,
  & > h3,
  & > h4,
  & > h5,
  & > h6,
  & > ul,
  & > ol,
  & > p,
  & > span,
  & > blockquote,
  & > .block.image.align.left.medium,
  & > .block.image.align.left.small,
  & > .block.image.align.right.medium,
  & > .block.image.align.right.small,
  .block.image.align.center figure,
  .event-details,
  .download-event,
  .block.separator.has--align--left .line,
  .block.separator.has--align--center .line,
  & > table,
  & > pre,
  & > .block.code {
    @include narrow-container-width();
    @include adjustMarginsToContainer($narrow-container-width + 2 * 36px);
  }

  & > h1.documentFirstHeading,
  & > .block.image.align.left,
  & > .block.image.align.right,
  & > .block.image.align.wide figure,
  & > .block.__button,
  & > .block.listing .listing-item,
  & > .block.listing .emptyListing,
  & > .block.listing.grid,
  & > .block h2.headline,
  & > .block.heading .heading-wrapper,
  & > .block.separator.has--align--full .line,
  & > .block.introduction .block-container,
  & > .block.teaser .grid-teaser-item.default,
  & > .table-of-contents,
  & > .slate blockquote {
    @include default-container-width();
  }

  & > .block.teaser,
  & > .block.image.align.full,
  & > .block.video.align.full,
  & > .block.maps.align.full {
    @include layout-container-width();
  }

  & > h1.documentFirstHeading {
    @include adjustMarginsToContainer($default-container-width);
  }

  & > .block {
    // This is the default, same color spacing for all blocks
    margin-bottom: $block-vertical-space;
  }

  &:last-child {
    margin-bottom: -1rem !important;
  }
}

// Setting a default, for all blocks
// We want all blocks in edit go layout by default
// But block's inner containers can go less (eg. grid)
#page-add,
#page-edit {
  [class*='block-editor-'] {
    @include layout-container-width();
    @include adjustMarginsToContainer($layout-container-width);
  }
}

// Add/Edit views
#page-add,
#page-edit {
  .block.slate .slate-editor,
  .block-editor-image.left.medium,
  .block-editor-image.left.small,
  .block-editor-image.right.medium,
  .block-editor-image.right.small,
  .block-editor-image.center figure,
  .block-editor-separator.has--align--left .block.separator,
  .block-editor-separator.has--align--center .block.separator,
  .block-editor-slateTable,
  .block-editor-codeBlock,
  .block-editor-mermaidBlock {
    @include narrow-container-width();
    @include adjustMarginsToContainer($narrow-container-width);
  }

  .block-editor-title h1,
  .block-editor-image.left.large,
  .block-editor-image.right.large,
  .block-editor-image.wide figure, // Wide images are always large
  .block-editor-image.wide.large figure,
  .block.introduction .slate-editor,
  .block.heading .heading-wrapper,
  .block-editor-listing .items,
  .block-editor-listing .listing.message,
  .block-editor-separator.has--align--full .block.separator,
  .block-editor-separator .block.separator.has--align--full,
  .block.teaser.has--align--center,
  .block-editor-teaser .grid-teaser-item.default,
  .block-editor-toc {
    @include default-container-width();
    @include adjustMarginsToContainer($default-container-width);
  }

  .block-editor-slate,
  .block-editor-image,
  .block-editor-slider,
  .block-editor-teaser,
  .block-editor-separator.has--align--left {
    @include layout-container-width();
    @include adjustMarginsToContainer($layout-container-width);
  }
}

// Grids adjustments (has to be paired with collections/grid.variables)
// TODO: Move to our own grid component
.block.__grid .ui.stackable,
.block.__grid h2.headline {
  @include default-container-width();
  max-width: calc(var(--default-container-width) + 1rem);
}

// Fix for Image Grid with only one image
#page-document .block.__grid .block.image.align.center figure {
  max-width: unset;
}

// handlers adjustments
body.has-toolbar.has-sidebar .drag.handle.wrapper {
  margin-left: -30px !important;
}

body.has-toolbar.has-sidebar .block .ui.basic.button.delete-button {
  margin-right: -30px !important;
}

.contenttype-file {
  a {
    text-decoration: underline;
  }
}

@import 'bgcolor-blocks-layout';
