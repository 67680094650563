// Block Teaser Standalone
#page-document .block.teaser {
  @include vertical-space-teaser();
}

.blocks-group-wrapper > .block.teaser,
.block-editor-teaser .block.teaser {
  // We want the image to extend in all its container width
  height: 100%;

  .grid-teaser-item.default {
    padding-bottom: 40px; // same as vertical spacing in margin-bottom
    border-bottom: 1px solid $black;
  }

  .grid-image-wrapper {
    width: 100%;
  }

  &.has--align--left,
  &.has--align--right {
    .grid-teaser-item.default {
      display: flex;

      .grid-image-wrapper {
        align-self: flex-start;
      }

      @media only screen and (max-width: $largest-mobile-screen) {
        flex-direction: column !important;
      }
    }
  }

  &.has--align--left {
    .grid-teaser-item.default {
      flex-direction: row;

      .grid-image-wrapper {
        margin-right: 20px;

        @media only screen and (max-width: $largest-mobile-screen) {
          margin-right: 0;
          margin-bottom: 26px;
        }
      }
    }
  }

  &.has--align--right {
    .grid-teaser-item.default {
      flex-direction: row-reverse;

      .grid-image-wrapper {
        margin-left: 20px;

        @media only screen and (max-width: $largest-mobile-screen) {
          margin-bottom: 26px;
          margin-left: 0;
        }
      }
    }
  }

  &.has--align--center {
    .grid-teaser-item.default {
      display: block;
      a {
        display: block;
      }

      .grid-image-wrapper {
        margin-bottom: 26px;
      }
    }
  }

  .content {
    @media only screen and (max-width: $largest-mobile-screen) {
      padding: 0 0.5rem;
    }
    .headline {
      margin-bottom: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
      @include headtitle1();
    }
    h2 {
      margin-top: 0;
      margin-bottom: 40px;
      @include text-heading-h2();
    }
    p {
      margin: 0;
      @include body-text();
    }
  }
}
