.block.slider {
  margin-right: auto;
  margin-left: auto;
}

.block.slider .slick-dots {
  bottom: 34px;
  line-height: 0;

  li {
    width: 40px;
    height: 40px;
    padding: 0;
    border-color: $lightgrey;
    background-color: $lightgrey;
    border-radius: 20px;

    button {
      width: 40px;
      height: 40px;
      padding: 0;
      border-color: $lightgrey;
      background-color: $lightgrey;
      border-radius: 20px;
    }

    &.slick-active {
      border-color: $grey;
      background-color: $grey;

      button {
        border-color: $grey;
        background-color: $grey;
      }
    }
  }
}

.block.slider .teaser-item-title {
  background: rgba(0, 0, 0, 0.6);

  .title {
    margin-bottom: 15px;

    h2 {
      font-family: $page-font-template;
      text-transform: uppercase;
    }
  }

  p {
    font-family: $alternate-fontname;
    font-size: 25px;
  }
}

.slick-slide {
  .highlight-image-wrapper {
    max-height: 500px;

    img {
      object-fit: cover;
    }
  }
}
