/*******************************
Simple Teaser
*******************************/
.grid-teaser-item {
  &.benefit {
    padding: 30px;
    margin: 0 10px;
    background-color: $lightgrey;
    color: $brandalternate;
    text-align: center;

    a {
      color: $brandalternate;
      text-align: center;

      .header.header-benefit {
        display: block;
        font-size: 2rem;
        text-align: center;

        .content {
          color: $brandalternate;
        }
      }

      .icon {
        display: inline-flex;
        color: $brand !important;
        fill: $brand !important;
      }

      .body-benefit {
        text-align: center;
      }
    }

    &:hover {
      @include animate-hover();
      @include theme-gradient();

      a {
        color: $brandconstrast;

        .header.header-benefit {
          color: $brandconstrast;

          .content {
            color: $brandconstrast;
          }
        }
      }

      .icon {
        color: $brandconstrast !important;
        fill: $brandconstrast !important;
      }
    }
  }
}
