.block.__grid {
  margin-top: 0;
  margin-bottom: 0;

  .teaser {
    margin-bottom: 0;
  }

  .headline {
    @include block-title();
  }
  .slate {
    margin: 0 !important;
    background-color: $lightgrey;
    p {
      padding: 1.5rem 1.5rem 2rem 1.5rem;
    }
    h2,
    h3 {
      padding: 3rem 1.5rem 0.5rem 1.5rem;
    }
  }
}

.grid-block-slate {
  .text-slate-editor-inner {
    height: 100%;
  }

  .slate-editor {
    height: 100%;
    margin: 0 !important;
    background-color: $lightgrey !important;
    p {
      padding: 1.5rem 1.5rem 2.4rem 1.5rem;
    }
    h2,
    h3 {
      padding: 1.5rem 1.5rem 1rem 1.5rem;
    }
  }
}

.grid-block-teaser {
  padding-bottom: 0 !important;
  .block.teaser {
    background-color: $lightgrey;
    .grid-teaser-item {
      height: 100%;

      .grid-image-wrapper {
        margin-bottom: 40px !important;
      }
      .content {
        padding: 0 20px 20px 20px;
        @include body-text();

        .headline {
          padding: 0 !important;
          margin-bottom: 20px;
          letter-spacing: 1px;
          text-transform: uppercase;
          @include headtitle1();
        }

        h2 {
          padding: 0;
          margin-bottom: 20px;
        }

        p {
          margin-bottom: 20px;
        }
      }
    }
  }
}

// Dynamic font sizes depending on number of columns for in-grid Teaser
.block.__grid {
  .one.column {
    .content h2 {
      margin-bottom: 40px !important;
      @include text-heading-h2();
    }
  }
  .two.column,
  .three.column {
    .grid-teaser-item .content {
      h2 {
        margin-bottom: 20px !important;
        @include text-heading-h3();
      }
    }
  }

  .four.column {
    .grid-teaser-item {
      .grid-image-wrapper {
        margin-bottom: 20px !important;
      }
      .content {
        .headline {
          @include headtitle2();
        }
        h2 {
          margin-bottom: 20px !important;
          @include text-heading-h4();
        }
      }
    }
  }
}

.grid-block-image {
  figcaption {
    position: absolute;
    z-index: 100;
    bottom: 0;
    padding: 1rem 1rem 0.5rem 1rem;

    .title {
      color: $white !important;
      font-size: 18px;
      letter-spacing: 1px;
      line-height: 22px;
    }

    .description {
      color: $white !important;
    }

    .credits {
      margin-bottom: 0;
      color: $white !important;
      font-size: 10px;
    }
  }
  img {
    z-index: revert !important;
  }
  figure {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin: 0 !important;
  }

  figure:after {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 35%;
    background-color: $black;
    content: '';
    opacity: 0.75;
  }
}

.block.__grid.has--backgroundColor--grey,
#page-add .block-editor-__grid.has--backgroundColor--grey,
#page-edit .block-editor-__grid.has--backgroundColor--grey {
  background-color: $lightgrey;
  .grid-teaser-item {
    background: white;
  }
  .slate {
    padding: 0 !important;
    margin: 0 1rem 1rem 1rem;
    background-color: $white;
  }

  .slate-editor {
    padding: 0 !important;
    margin: 0 1rem 1rem 1rem;
    background-color: $white !important;
  }

  .grid-block-image {
    .block.image {
      background: white;
    }
  }
}
