.block.listing {
  &.grid {
    @include h2_headline();

    .items {
      .listing-item {
        .card-container {
          &:hover {
            @include animate-hover();
            @include theme-gradient();

            .content,
            h2,
            p {
              color: $brandconstrast;
            }
          }

          .content {
            h2 {
              padding-top: 15px !important;
              padding-bottom: 20px !important;
              font-size: 24px;
              font-weight: 700;
              line-height: 30px;
            }

            p {
              margin: 0;
              font-size: 18px;
              font-weight: 300;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}
