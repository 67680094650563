.block.introduction {
  @include vertical-space-introduction();

  .block-container p,
  .block-container ul,
  .block-container ol,
  .slate-editor p,
  .slate-editor ul,
  .slate-editor ol {
    margin: 0;
    @include introduction();
    strong,
    strong span {
      font-weight: bold;
    }
    em,
    em span {
      font-style: italic;
    }
  }
}
