// This theme extends the volto-light-theme
// it is injected

:root {
  --narrow-container-width: #{$_narrow-container-width};
  --default-container-width: #{$_default-container-width};
  --teaser-images-aspect-ratio: #{$teaser-images-aspect-ratio};
}

@import 'root';
@import 'typo-custom';
@import 'logo';
@import 'layout';
@import 'listing';
@import 'header';
@import 'breadcrumbs';
@import 'navigation';
@import 'search';
@import 'footer';
@import 'socialnetworks';
@import 'blocks';
@import 'blocks_accordion';
@import 'blocks_button';
@import 'blocks_form';
@import 'blocks_grid';
@import 'blocks_image';
@import 'blocks_listing';
@import 'blocks_separator';
@import 'blocks_simpleteaser';
@import 'blocks_slider';
@import 'view_certificate';
// Import content-related styles
@import 'content';
