body.contenttype-certificate {
  .certificate-view.view-wrapper {
    max-width: var(--layout-container-width);
    min-height: 500px;
    margin-right: auto;
    margin-left: auto;

    table {
      width: 100%;

      th {
        padding-right: 1rem;
        text-align: left;
      }

      td {
        text-align: left;
      }
    }

    .certificateCheckerWrapper {
      max-width: var(--narrow-container-width);
      margin-right: auto;
      margin-left: auto;

      .certificateChecker {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        background-color: #ecebeb;

        .certificate-info,
        .training-info,
        .student-info {
          width: 50%;
          align-self: stretch;
          padding: 2rem;
          margin: 0.5rem;
        }

        .certificate-info {
          width: 100%;
        }
        @media only screen and (max-width: $largest-mobile-screen) {
          flex-direction: column;

          .training-info,
          .student-info {
            min-width: 100%;
          }
        }
      }
    }
  }
}
