#page-document .accordion-block {
  max-width: var(--narrow-container-width);
  margin-right: auto;
  margin-left: auto;

  .blocks-group-wrapper {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

body.contenttype-training {
  .accordion-block {
    .ui.styled.accordion {
      .accordion-title {
        padding-left: 0px;
        background-color: $page-background;
        color: $black;
      }
    }
  }
}
