.block.__grid {
  @include h2_headline();

  .block.teaser {
    .grid-teaser-item {
      &:hover {
        @include animate-hover();
        @include theme-gradient();

        .content,
        h2 {
          color: $brandconstrast;
        }
      }
    }
  }
}
